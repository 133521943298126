import React from "react";
import Menu from "./menu";
import Expandable from "../../components/UICore/Expandable";
import Marquee from "react-fast-marquee";
import Card from "../../components/UICore/Card";
import Constants from "../../constants";
import {
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
} from "react-icons/tb";
import { AiOutlineArrowRight } from "react-icons/ai";

import { FaInstagram, FaPlus, FaPlusCircle } from "react-icons/fa";

import { MdEmail, MdPhone } from "react-icons/md";

import { useState } from "react";
import useForms from "../../hooks/useForms";
import Div100vh from "react-div-100vh";
import { usePostHog } from "posthog-js/react";
import StepIndicator from "../../components/StepIndicator";
import GoogleReviewsCarousel from "./GoogleReviewsCarousel";
import { googleReviews } from "./reviews";
const numberToIcon = (number: number) => {
  switch (number) {
    case 1:
      return <TbCircleNumber1Filled size={34} />;
    case 2:
      return <TbCircleNumber2Filled size={34} />;
    case 3:
      return <TbCircleNumber3Filled size={34} />;
    default:
      return <TbCircleNumber1Filled size={34} />;
  }
};

interface StepItem {
  title: string;
  child: React.ReactNode;
}
interface FAQItem {
  title: string;
  text: string;
}

const Landing: React.FC = () => {
  const postHogUserIdentifier = usePostHog().get_distinct_id();
  const handleNavigation = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      const topPosition = section.offsetTop - 100; // Adjust space above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  const faqData: FAQItem[] = [
    {
      title: "Is this a dating app?",
      text: "Well, no. This is not an app; there's no swiping involved. Our AI-powered, human-curated matchmaking process finds you compatible people based on your preferences and values. We're available and will make introductions via call and text so no need to use any app.",
    },
    {
      title: "How are members vetted?",
      text: "Our screening process and invite-only outreach ensures that the members you meet on Curate are high-caliber people looking for meaningful connections. We vet our members based on their profiles, phone interviews, responses, and referrals from friends.",
    },
    {
      title: "Where is this available?",
      text: "NYC, SF, LA. More cities coming soon.",
    },
  ];

  return (
    <div className=" bg-white flex flex-col items-center max-w-screen overflow-hidden">
      {/* <section className="flex flex-col items-center w-full px-6 md:w-9/12 space-y-4 mt-0 "></section> */}

      <Div100vh className="justify-center flex flex-col relative w-full">
        <div className="flex flex-col items-end w-full p-6 space-y-4  h-8 absolute top-0 right-0">
          <a
            href="https://www.instagram.com/curate.date/"
            target="_blank"
            className="hidden md:flex z-50  p-3"
          >
            <FaInstagram size={28} className="cursor-pointer" />
          </a>
        </div>
        {/* <img
          src={require("../../assets/media/coupleWalking2.gif")}
          alt="couple walking"
          className="absolute top-0 left-0 min-w-full min-h-full object-cover z-0"
          style={{ objectFit: "cover" }}
        /> */}
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          style={{ objectFit: "cover" }}
          onLoadedMetadata={(e) => {
            const video = e.target as HTMLVideoElement;
            video.play().catch((error) => {
              console.error("Autoplay prevented", error);
              // alert("Autoplay prevented");
              // Fallback: show a play button or poster image
            });
          }}
        >
          <source
            src={require("../../assets/media/coupleSunset.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-25 z-5"></div>
        <div className="relative z-10 flex flex-col items-center w-full">
          <div className="flex flex-col items-center my-4 space-y-4">
            <div className="flex flex-col md:flex-row items-center justify-between space-x-8">
              <div className="space-y-4 items-center justify-center flex flex-col text-center md:text-left md:items-start px-4">
                <h1 className="font-inter md:text-6xl text-4xl  md:mt-0 text-white">
                  {/* mt-24 */}
                  Goodbye Swiping.
                  <br />
                  Hello <b className="font-semibold">Matchmaker</b>.
                </h1>
                <p className="text-2xl font-lato text-white">
                  Matches so good, you'll never go back to the apps.
                </p>
                <br className="md:hidden" />
                <div className="flex flex-row space-x-4">
                  <a
                    href={`${Constants.APPLICATION_LINK}?pId=${postHogUserIdentifier}`}
                    target="_blank"
                  >
                    <button className="bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-3 px-4 font-inter">
                      Find Your Match
                    </button>
                  </a>
                  <button
                    className="bg-white hover:bg-[#bd9e36] text-black font-semibold rounded-full py-3 px-4 font-inter"
                    onClick={(e) => handleNavigation(e, "welcome")}
                  >
                    How It Works
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Div100vh>
      <Menu />
      <section
        className="flex flex-col items-center justify-center w-full  py-12"
        id="welcome"
      >
        <h2 className="font-inter text-4xl py-8 text-center">
          A New Way to Get Introduced
        </h2>
        <div className="flex flex-col md:flex-row md:space-x-12 items-stretch justify-center w-full max-w-4xl">
          <div className="flex flex-col items-center justify-between w-full md:w-5/12 p-8 rounded-lg h-full">
            <h2 className="font-inter text-2xl font-semibold mb-4 text-gray-700">
              AI-Powered Matchmaking
            </h2>
            <p className="text-lg font-serif  h-full text-start text-gray-700">
              Our AI isn’t just smart—it’s totally <b>in sync with you</b>. We
              get to know you through a <b>quick quiz</b>, a chill{" "}
              <b>voice call</b>, and a<b> fun survey</b> about what you’re into
              physically (because
              <b> looks and personality</b> both matter!). Then, using{" "}
              <b>cutting-edge tech</b>, we scan <b>350,000+ profiles</b> finding 
              the best matches for you based on your <b>preferences</b>,{" "}
              <b>values</b>, and
              <b> vibe</b>.
            </p>
          </div>

          <div className="w-full md:w-1/12 flex items-center justify-center">
            <FaPlus size={32} />
          </div>

          <div className="flex flex-col items-center justify-between w-full md:w-5/12  p-8 rounded-lg h-full">
            <h2 className="font-inter text-2xl font-semibold mb-4 text-gray-700">
              Curated Introductions
            </h2>
            <p className="text-lg font-serif text-start text-gray-700">
              We <b>text you</b> potential matches. Like someone? We'll {" "}
              <b>make the first move for you</b>, sharing your bio with them,{" "}
              <b>hyping you up</b>, and explaining why you two would <b>hit it off</b>.
              If they're into it too, we'll <b>start a group chat</b> on text,
              iMessage, or Instagram <b>introducing you both</b>. From there, the
              connection's all yours -- <b>no awkward first messages needed</b>.
            </p>
          </div>
        </div>
      </section>

      <section
        className="flex flex-col items-center w-full px-8 md:px-12  space-y-4 mt-0 pb-24 bg-gradient-to-b from-white to-[#fde8a3]"
        id="how-it-works"
      >
        <h2 className="font-inter text-4xl pt-12">How It Works</h2>
        <div className="py-4 w-full">
          <StepIndicator stepNumber={1} />
        </div>
        <div className="flex flex-col items-center justify-center w-full max-w-[1400px]">
          <div className="flex flex-col md:flex-row justify-between space-y-8 w-full items-center">
            <div className="flex flex-col items-start space-y-8 w-full max-w-[400px]">
              <h2 id="about" className="font-inter text-4xl  ">
                Are You a Good Fit?
              </h2>
              <p className="text-lg  font-serif">
                Curate isn’t for everyone. We cater to <b>go-getters</b> who
                value their time and won’t settle for anything less than an{" "}
                <b>exceptional partner</b>. Our clients are{" "}
                <b>CEOs, entrepreneurs, doctors, lawyers, and creatives</b>{" "}
                ready to bring that same energy to finding lasting love.{" "}
                <b>Apply now</b> and complete a quick screening call. We’ll let
                you know within <b>24 hours</b> if you’re in.
              </p>

              <a
                href={`${Constants.APPLICATION_LINK}?pId=${postHogUserIdentifier}`}
                target="_blank"
                className="text-lg font-lato hover:underline items-center justify-center flex flex-row font-semibold"
              >
                Curate Application
                <AiOutlineArrowRight className="ml-2 text-lg" size={22} />
              </a>
            </div>
            <img
              src={require("../../assets/media/woman-on-phone.png")}
              alt="phone"
              className="w-4/12 hidden md:flex p-12"
            />
          </div>
        </div>
        <div className="py-8 w-full">
          <StepIndicator stepNumber={2} />
        </div>
        <div className="flex flex-col items-center justify-center w-full max-w-[1400px]">
          <div className="flex flex-col md:flex-row justify-between space-y-8 w-full items-center">
            <div className="flex flex-col items-start space-y-8 w-full max-w-[400px]">
              <h2 id="about" className="font-inter text-4xl  ">
                Finding Your Best Matches
              </h2>
              <p className="text-lg font-serif">
                Once you’re accepted, we get to work{" "}
                <b>finding your perfect match</b>. Our{" "}
                <b>next-level algorithm</b> (built by dating gurus and tech
                wizards) checks <b>100+ compatibility factors</b>. But we don't
                stop there. Our <b>matchmaker squad</b> personally checks your
                matches to ensure quality. You’ll get a{" "}
                <b>detailed profile and bio</b> with each match, along with a
                note on why we think you’ll click. Ready to meet someone? Just
                hit "<b>Get Introduced</b>", or give us feedback to{" "}
                <b>refine your matches</b> even more.
              </p>

              <a
                href="https://www.linkedin.com/company/curate-matchmaking/"
                target="_blank"
                className="text-lg font-lato hover:underline items-center justify-center flex flex-row font-semibold"
              >
                Our Team
                <AiOutlineArrowRight className="ml-2 text-lg" />
              </a>
            </div>
            <img
              src={require("../../assets/media/woman-texting.png")}
              alt="phone"
              className="w-4/12 hidden md:flex p-12"
            />
          </div>
        </div>
        <div className="py-8 w-full">
          <StepIndicator stepNumber={3} />
        </div>

        <div className="flex flex-col items-center justify-center w-full max-w-[1400px]">
          <div className="flex flex-col md:flex-row space-y-8 w-full items-center justify-between">
            <div className="flex flex-col items-start space-y-8 w-full max-w-[400px]">
              <h2 id="about" className="font-inter text-4xl  ">
                Meet Your Match
              </h2>
              <p className="text-lg font-serif">
                When you’re ready, we’ll <b>personally reach out</b> to your
                match and share why we think you’re a catch. If they’re into it, we’ll
                make an <b>intro</b> via <b>text/iMessage</b> or{" "}
                <b>Instagram</b>. And because we value respect, all Curate
                members agree to our <b>no-ghost/no-flake</b> policy—keeping
                things real after an introduction. If they aren't into it, no
                worries—we’ll keep sending you matches until we find the right
                one!
              </p>

              <a
                href={`/curate-whitepaper.pdf`}
                target="_blank"
                className="text-lg font-lato hover:underline items-center justify-center flex flex-row font-semibold"
              >
                Curate AI Matchmaker Whitepaper
                <AiOutlineArrowRight className="ml-2 text-lg" />
              </a>
            </div>
            <img
              src={require("../../assets/media/park bench.png")}
              alt="phone"
              className="w-4/12 hidden md:flex p-12"
            />
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center w-full space-y-4 justify-center py-16 text-center md:text-left">
        <h1 className="font-inter text-4xl  ">Who We Are</h1>
        <p className="text-2xl font-lato md:px-12 px-8 max-w-[1400px] text-center ">
          Curate was founded by a team of relationship psychologists, tech
          innovators, and successful entrepreneurs who recognized the need for a
          more sophisticated approach to modern dating. Our mission is to
          revolutionize the way people find love by combining the best of human
          intuition with advanced technology.
        </p>
        <a href={Constants.APPLICATION_LINK} target="_blank">
          <button className="bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-3 px-4 my-4">
            Find Your Match
          </button>
        </a>
      </section>

      <section className="flex flex-col items-center w-full px-6 md:px-12 max-w-[1400px] py-12">
        <h1 className="font-inter text-4xl  ">From our community</h1>
        <GoogleReviewsCarousel reviews={googleReviews} />
      </section>

      <section className="flex flex-col items-center  space-y-4 justify-center py-12 text-center md:text-left w-full">
        <h1 className="font-inter text-4xl  ">A New Way to Get Introduced</h1>
        <p className="text-2xl font-lato">Rediscover the Joy of Dating</p>
        <a href={Constants.APPLICATION_LINK} target="_blank">
          <button className="bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-4 px-6 my-4">
            Find Your Match
          </button>
        </a>
      </section>
      <section className="flex flex-row items-center justify-between w-full  px-4 md:px-16">
        <img
          src={require("../../assets/media/roses-sketch.png")}
          alt="phone"
          className="w-3/12 hidden md:flex"
        />
        <div className="md:w-9/12 w-full  md:p-12 space-y-4 items-center justify-center flex flex-col">
          <h2 id="faq" className="font-inter text-4xl  ">
            FAQ
          </h2>
          <div className="flex flex-col w-full space-y-3 items-center">
            {faqData.map((item, index) => (
              <Expandable
                key={index}
                previewText={item.title}
                expandedContent={item.text}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center  space-y-5 justify-center font-lato md:mt-0  w-full py-12">
        <h2 id="support" className="font-playfair text-3xl font-semibold">
          Support
        </h2>
        <p className="text-lg text-center">Our team is here to help you 24/7</p>
        <Card className="flex flex-row space-x-2 items-center justify-center w-[240px]">
          <MdEmail size={32} />
          <p className="text-lg font-lato">support@curate.date</p>
        </Card>

        <Card className="flex flex-row space-x-2 items-center justify-center w-[240px]">
          <MdPhone size={32} />
          <p className="text-lg font-lato">+1 (212)-287-5646</p>
        </Card>
      </section>
      <footer className="flex flex-col space-y-1 h-[40px] py-8 border-t w-full items-center justify-center">
        <div className="flex flex-row space-x-2 items-center justify-center">
          <a
            href="/privacy.html"
            className="text-sm font-lato text-slate-500 underline"
          >
            Privacy Policy
          </a>
          <a
            href="/terms.html"
            className="text-sm font-lato text-slate-500 underline"
          >
            Terms of Service
          </a>
        </div>
        <p className="text-sm font-lato text-slate-500"></p>
      </footer>
    </div>
  );
};

export default Landing;
