import React, { FC } from "react";
import Div100vh from "react-div-100vh";
import { IoMdClose } from "react-icons/io";
import { IoLogoApple } from "react-icons/io";
import MembershipOptions from "../../components/MembershipOptions";

interface BillingSheetProps {
  onClose: () => void;
  sessionId: string | null;
}

const BillingSheet: FC<BillingSheetProps> = ({ onClose, sessionId }) => {
  return (
    <div className=" bg-[#f4f1ec] flex flex-col items-center  pb-4 space-y-4 px-2  flex-grow">
      {/* <div className="flex items-center justify-end flex-row w-full px-2 h-2">
          <IoMdClose
            className="text-2xl text-gray-400 cursor-pointer mt-2"
            onClick={onClose}
          />
        </div> */}
      <p className="text-2xl font-bold font-playfair text-gray-400 text-center">
        Join Curate
      </p>
      <p className="italic text-center font-lato px-4">
        Get personalized, high-quality introductions to vetted members each week
      </p>
      <a href="/" className=" my-2 mb-4 underline cursor-pointer">
        How it works
      </a>

      <MembershipOptions />
    </div>
  );
};

export default BillingSheet;
