import React, { useEffect, useState } from "react";
import {
  submitFitFinderResponse,
  getFirstFitFinderImages,
  getPreferencesString,
  onFitFinderGameComplete,
} from "../services/fitFinderService";
import { toast } from "react-toastify";

import { useSearchParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

const useFitFinder = () => {
  const posthog = usePostHog();

  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(0);
  const TOTAL_STEPS = 15;
  const [image1Id, setImage1Id] = useState("");
  const [image2Id, setImage2Id] = useState("");
  const [image1Base64, setImage1Base64] = useState("");
  const [image2Base64, setImage2Base64] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [image1MostRcentlyClicked, setImage1MostRcentlyClicked] =
    useState(false);
  const [preferencesString, setPreferencesString] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (currentStep >= TOTAL_STEPS) {
      setIsFinished(true);
      if (searchParams.get("sessionId")) {
        onFitFinderGameComplete(searchParams.get("sessionId") as string).catch(
          (error) =>
            console.error("Error calling onFitFinderGameComplete:", error)
        );
      }
    }
  }, [currentStep, searchParams]);

  useEffect(() => {
    const initializeFitFinder = async () => {
      setIsLoading(true);
      try {
        if (!searchParams.get("sessionId")) {
          throw new Error("Invalid session ID");
        }
        const initialImages = await getFirstFitFinderImages(
          searchParams.get("sessionId") as string
        );
        if (initialImages.image1_id === null) {
          toast.error("No more profiles to view!");
          return;
        }
        setImage1Id(initialImages.image1_id);
        setImage2Id(initialImages.image2_id);
        setImage1Base64(initialImages.image1_base64);
        setImage2Base64(initialImages.image2_base64);
        posthog?.identify(initialImages.posthog_user_id, {});
      } catch (error) {
        console.error("Error initializing fit finder:", error);
        toast.error(
          "Failed to initialize fit finder. Please try again or contact support."
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (searchParams.get("sessionId")) {
      initializeFitFinder();
    } else {
      toast.error("Invalid session ID. Please check the URL and try again.");
    }
  }, [searchParams.get("sessionId")]);

  useEffect(() => {
    const fetchPreferencesString = async () => {
      if (isFinished && searchParams.get("sessionId")) {
        try {
          const preferences = await getPreferencesString(
            searchParams.get("sessionId") as string
          );
          setPreferencesString(preferences);
        } catch (error) {
          console.error("Error fetching preferences string:", error);
          toast.error(
            "Failed to fetch preferences. Please try again or contact support."
          );
        }
      }
    };

    fetchPreferencesString();
  }, [isFinished, searchParams]);

  const handleImageClick = async (imageId: string) => {
    try {
      if (!searchParams.get("sessionId")) {
        throw new Error("Invalid session ID");
      }

      setImage1MostRcentlyClicked(imageId == image1Id);

      setIsLoading(true);
      const preferredImage = imageId === image1Id ? 1 : 2;
      const response = await submitFitFinderResponse(
        image1Id,
        image2Id,
        preferredImage,
        searchParams.get("sessionId") as string
      );

      setImage1Id(response.next_image1_id);
      setImage2Id(response.next_image2_id);
      setImage1Base64(response.next_image1_base64);
      setImage2Base64(response.next_image2_base64);
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error("Error handling image click:", error);
      toast.error(
        "Failed to fetch next images. Please try again or contact support."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    image1Id,
    image2Id,
    image1Base64,
    image2Base64,
    currentStep,
    handleImageClick,
    TOTAL_STEPS,
    isFinished,
    isLoading,
    image1MostRcentlyClicked,
    preferencesString,
  };
};

export default useFitFinder;
