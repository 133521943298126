import React, { FC } from "react";
import Div100vh from "react-div-100vh";
import { AiOutlineArrowRight } from "react-icons/ai";

import {
  FaChevronRight,
  FaUser,
  FaHeart,
  FaHandshake,
  FaCog,
  FaQuestionCircle,
} from "react-icons/fa";

const Account: FC = () => {
  return (
    <div className="flex flex-col items-center max-w-screen w-full">
      <Div100vh
        className={`bg-[#f4f1ec] flex flex-col items-center w-screen min-h-screen max-w-[400px]`}
      >
        {/* Header */}
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400 z-25"
          >
            Curate
          </a>

          <a
            href="mailto:support@curate.date"
            className="text-md font-lato text-gray-400 underline"
          >
            support
          </a>
        </div>
        <section className="flex flex-col items-center w-full space-y-3">
          <p className="text-lg font-lato text-start w-full items-start px-4 font-bold text-gray-700 pt-8">
            General
          </p>

          <div className="flex flex-col items-center justify-start w-full bg-white rounded-sm border border-gray-200 px-4">
            <a
              href="/account/client-profile"
              className="flex flex-row items-center justify-start w-full py-2"
            >
              <FaUser className="mr-2 text-gray-600" />
              <h2 className="text-lg font-lato  w-full hover:underline inline">
                Client Profile [internal use]
              </h2>{" "}
              <FaChevronRight size={18} />
            </a>
            <a
              href="/account/matches"
              className="flex flex-row items-center justify-start w-full py-2 border-t border-gray-200"
            >
              <FaHeart className="mr-2 text-gray-600" />
              <h2 className="text-lg font-lato  w-full hover:underline inline">
                Matchmaker Matches (2)
              </h2>{" "}
              <FaChevronRight size={18} />
            </a>
            <a
              href="/account/introductions"
              className="flex flex-row items-center justify-start w-full py-2 border-t border-gray-200"
            >
              <FaHandshake className="mr-2 text-gray-600" />
              <h2 className="text-lg font-lato  w-full hover:underline inline">
                Personal Introductions (0)
              </h2>{" "}
              <FaChevronRight size={18} />
            </a>
          </div>

          <p className="text-lg font-lato text-start w-full items-start px-4 font-bold text-gray-700 pt-10">
            Account
          </p>

          <div className="flex flex-col items-center justify-start w-full bg-white rounded-sm border border-gray-200 px-4">
            <a
              href="/account/settings"
              className="flex flex-row items-center justify-start w-full py-2"
            >
              <FaCog className="mr-2 text-gray-600" />
              <h2 className="text-lg font-lato  w-full hover:underline inline">
                Account Settings
              </h2>{" "}
              <FaChevronRight size={18} />
            </a>
            <a
              href="sms:+12122875646"
              className="flex flex-row items-center justify-start w-full py-2 border-t border-gray-200"
            >
              <FaQuestionCircle className="mr-2 text-gray-600" />
              <h2 className="text-lg font-lato  w-full hover:underline inline">
                Account Help
              </h2>{" "}
              <FaChevronRight size={18} />
            </a>
          </div>
        </section>
      </Div100vh>
    </div>
  );
};

export default Account;
